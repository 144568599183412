<template>
  <CCard>
    <CCardBody>
      <div class="clearfix">
        <button
          type="button"
          class="btn btn-primary float-xl-right"
          @click="navToNewSymptoms"
        >
          New
        </button>
      </div>

      <CDataTable
        :items="items"
        :fields="fields"
        striped
        clickable-rows
        column-filter
        table-filter
        items-per-page-select
        :items-per-page="20"
        sorter
        ref="vuetable"
        pagination
      >
        <template #status-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'status',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Upcoming</option>
            <option value="2">Pending</option>
            <!-- <option value="3">Past</option> -->
            <option value="4">Started</option>
            <option value="5">Past</option>
          </select>
        </template>
        <template #index="data">
          <td>
            {{ data.index + 1 }}
          </td>
        </template>
      

        <template #lowerLimit="{ item }">
          
          <td class="font-weight-bold" v-if="item.lowerLimit">
            {{ item.lowerLimit }}
          </td>
          <td class="font-weight-bold" v-else>--</td>
        </template>
        <template #upperLimit="{ item }">
          
          <td class="font-weight-bold" v-if="item.upperLimit">
            {{ item.upperLimit }}
          </td>
          <td class="font-weight-bold" v-else>--</td>
        </template>

    

        <!-- <template #view="{ item }">
         <td class="py-1">
         <CButton togglerText="View" color="info" size="sm">
              <CDropdownItem
                :to="{
                  name: 'Briefing',
                  params: { briefingId: item.briefingId, mode: 'view' },
                }"
                >View
              </CDropdownItem>
         </CButton>
         
         </td>
        </template> -->
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
const fields = [
  { key: "index", _style: "min-width:200px", _classes: "font-weight-bold" },
  { key: "lowerLimit", _style: "min-width:200px", _classes: "font-weight-bold" },
  {
    key: "upperLimit",
    label: "UpperLimit",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  
  
];
export default {
  name: "SettingValues",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      download: "",
    };
  },
  methods: {
    
//  getLocations() {
//       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/civil/jobs")
//         .then((response) => response.json())
//         .then((data) => (this.items = data));
//     },
     getValues() {
      const url = process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/settingValues";
      console.log("SettingValue", url);
       this.apiGetSecure(
        url
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   navToNewSymptoms() {
      this.$router.push({ name: "SettingValue", params: { mode: "new" } });
    },
   
  },
  mounted() {
    this.getValues();
  },
};
</script>